import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "PersonaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PersonaPage",
            ss: ss,
            ajax: {
                "url": ss.indexPersona(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Persona', name: 'p.Persona', title: 'Persona' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            unidadAcademicas: [],
            rols: [],
            personas: [],
            persona: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            password: {},
            estudianteSaga:{},
            docenteSaga:{},
            personaDocumentos:[],
            personaProgramas:[],
            personaSubProgramas:[],
            documentoPer:{},
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            documentoPerProg:{},
            botonGuardar:false

        };
    },
    methods: {
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.persona.Foto = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

        loadFileFirma(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.persona.FirmaDigitalizada = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        loadArchivo(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.documentoPer.Archivo = result.data.data;
                            this.actualizarArchivo();
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

        loadArchivoDos(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.documentoPerProg.Archivo = result.data.data;
                            this.actualizarArchivoDos();
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getRol() {
            this.ss.listRol().then(
                (result) => {
                    let response = result.data;
                    this.rols = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newPersona() {
            this.persona = {};
            this.$refs['frm-persona'].show();
        },

        newPersonaSaga(){
            this.persona={};
            this.$refs['personasaga'].show();
        },

        newDocenteSaga(){
            this.persona={};
            this.$refs['docentesaga'].show();
        },

        cancelPersonaSaga(){
            this.$refs['personasaga'].hide();
        },

        cancelDocenteSaga(){
            this.$refs['docentesaga'].hide();
        },


        bucarEstudiante() {

            if(!this.persona.CI){
                this.$swal({
                    title: "Error al bucar",
                    text: "Por favor ingrese un numero de carnet",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }
            this.isLoading=true;
            this.ss.bucarEstudiante(this.persona.CI).then(
                (result) => {
                    let response = result.data;
                    this.estudianteSaga = response.data;

                    this.persona.Nombres = this.estudianteSaga.Nombre;
                    this.persona.ApellidoPaterno = this.estudianteSaga.ApPaterno;
                    this.persona.ApellidoMaterno = this.estudianteSaga.ApMaterno;
                    this.persona.Persona = this.estudianteSaga.Alumno;
                    // this.persona.CodigoSaga = this.estudianteSaga.CodAlumno;
                    // this.persona.CI = this.estudianteSaga.CI;
                    this.persona.email = this.estudianteSaga.EMail;
                    this.persona.CorreoEmi = this.estudianteSaga.email_emi;

                    // this.persona.UnidadAcademica = this.estudianteSaga.idUnidadAcademica;

                    this.persona.UnidadAcademica = response.unidadAca;

                    // console.log(response);



                    
                    // this.persona.Especialidad = this.estudianteSaga.idEspecialidad;
                    // this.persona.NEspecialidad = this.estudianteSaga.Especialidad;
                    // this.persona.NivelAcademico = this.estudianteSaga.idNivelAcad;
                    // this.persona.NNivelAcademico = this.estudianteSaga.NivelAcad; 
                    


                    if(response.success == true){
                        console.log("true");
                        this.$swal({
                            title: "Exito",
                            text: "Estudiante encontrado",
                            icon: "success",
                            dangerMode: true,
                        })
                        this.isLoading=false;


                        this.persona.password = this.persona.CI;
                        this.persona.Rol = 5;

                        this.$refs['personasaga'].hide();

                        this.$refs['frm-persona'].show();
                    }

                    if(response.success == false){

                        console.log("false");

                        this.$swal({
                            title: "Error al bucar",
                            text: "No se encontro al Estudiante",
                            icon: "error",
                            dangerMode: true,
                        })
                        this.isLoading=false;

                        return;
                    
                    }
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
                this.botonSaga=false;
            });
        },

        bucarDocente() {

            if(!this.persona.CI){
                this.$swal({
                    title: "Error al bucar",
                    text: "Por favor ingrese un numero de carnet",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }
            this.isLoading=true;
            this.ss.bucarEstudiante(this.persona.CI).then(
                (result) => {
                    let response = result.data;
                    this.docenteSaga = response.data;

                    this.persona.Nombres = this.docenteSaga.Nombre;
                    this.persona.ApellidoPaterno = this.docenteSaga.ApPaterno;
                    this.persona.ApellidoMaterno = this.docenteSaga.ApMaterno;
                    this.persona.Persona = this.docenteSaga.Alumno;
                    // this.persona.CodigoSaga = this.docenteSaga.CodAlumno;
                    // this.persona.CI = this.docenteSaga.CI;
                    this.persona.email = this.docenteSaga.EMail;
                    this.persona.CorreoEmi = this.docenteSaga.email_emi;

                    // this.persona.UnidadAcademica = this.docenteSaga.idUnidadAcademica;

                    this.persona.UnidadAcademica = response.unidadAca;

                    console.log("docente",response.data);



                    
                    // this.persona.Especialidad = this.docenteSaga.idEspecialidad;
                    // this.persona.NEspecialidad = this.docenteSaga.Especialidad;
                    // this.persona.NivelAcademico = this.docenteSaga.idNivelAcad;
                    // this.persona.NNivelAcademico = this.docenteSaga.NivelAcad; 
                    

                    this.isLoading=false;

                    if(response.success == true){
                        console.log("true");
                        this.$swal({
                            title: "Exito",
                            text: "Estudiante encontrado",
                            icon: "success",
                            dangerMode: true,
                        })

                        this.persona.password = this.persona.CI;
                        this.persona.Rol = 4;

                        this.$refs['personasaga'].hide();

                        this.$refs['frm-persona'].show();
                    }

                    if(response.success == false){

                        console.log("false");

                        this.$swal({
                            title: "Error al bucar",
                            text: "No se encontro al Estudiante",
                            icon: "error",
                            dangerMode: true,
                        })
                        return;
                    
                    }
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
                this.botonSaga=false;
            });
        },


        showPersona(id) {
            this.isLoading=true;
            this.ss.showPersona(id).then(
                (result) => {
                    let response = result.data;
                    this.persona = response.data;
                    this.getPersonaDocumentos();
                    this.getPersonaProgramas();
                    this.$refs['view-persona'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editPersona() {
            this.botonGuardar=true;
            this.$refs['frm-persona'].show();
            this.$refs['view-persona'].hide();
        },
        cancelPersona() {
            if (this.persona.id) {
                this.$refs['view-persona'].show();
            }
            this.$refs['frm-persona'].hide();
            this.botonGuardar=false;

        },

        verificarRegPersona() {

            
            this.isLoading=true;
            this.ss.verificarPersona(this.persona.CI).then(
                (result) => {
                    let response = result.data;
                    // this.persona = response.data;
                    if(response.success == false){
                        this.savePersona();
                    }

                    if(response.success == true && this.botonGuardar==true){
                        this.savePersona();
                        return;
                    }


                    if(response.success == true ){
                        this.persona={};
                        this.$refs['frm-persona'].hide();
                        this.$swal({
                            title: "La persona ya se encuentra registrada",
                            text: "Verifique los datos",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                        })
                        this.isLoading=false;

                    }
                    


                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });

        },

        savePersona() {
            this.ss.storePersona(this.persona).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    this.isLoading=false;

                    console.log(response);
                    this.$refs['frm-persona'].hide();
                    this.showPersona(response.data.id);
                    this.$refs['datatable-persona'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deletePersona() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyPersona(this.persona)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-persona'].hide();
                                this.$refs['datatable-persona'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cambiopassword() {
            this.$refs['view-persona'].hide();
            this.$refs['view-password'].show();
        },
        cancelPassword() {
            this.$refs['view-password'].hide();
            this.$refs['view-persona'].show();
        },
        changePassword() {
            this.password.Persona = this.persona.id;
            this.ss.changePassword(this.password)
                .then( result => {
                    let response = result.data;
                    if ( response.success ) {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['view-password'].hide();
                        this.$refs['view-persona'].show();
                    } else {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Oops',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                })
                .catch( error => {
                    console.log( error );
                    this.$bvToast.toast(
                        'Error al guardar el registro',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.errorBag = error.data.errors;
                })
        },

        getPersonaDocumentos() {
            this.ss.listPersonaDocumento(this.persona.id).then(
                (result) => {
                    let response = result.data;
                    this.personaDocumentos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        cargardocumentos(){
            this.ss.storePersonaDocumento(this.persona).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    this.getPersonaDocumentos();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        
        deletePersonaDocumento(obperdoc) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete.isConfirmed) {
                        this.ss.destroyPersonaDocumento(obperdoc)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )

                                this.getPersonaDocumentos();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        getPersonaProgramas() {
            this.ss.listPersonaPrograma(this.persona.id).then(
                (result) => {
                    let response = result.data;
                    this.personaProgramas = response.data;


                }
            ).catch(error => {
                console.log(error);
            });
        },

        syncProgramas(){

            this.isLoading=true;
            this.ss.sincronizarSagaProg(this.persona.CI).then(
                (result) => {
                    let response = result.data;
                    // this.persona = response.data;
                    if(response.success == false){
                        this.$swal({
                            title: "No se encontraron programas a los que esté inscrito la persona",
                            text: "Verifique el numero de carnet",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                        })
                        this.isLoading=false;

                        return;
                    }else{
                        this.getPersonaProgramas();
                    }
                    console.log(response.data)
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        verSubProgramas(idpp){
            this.personaSubProgramas=[];
            this.ss.listPersonaProgramaSub(idpp).then(
                (result) => {
                    let response = result.data;
                    this.personaSubProgramas = response.data;
                    this.$refs['subprogramas'].show();

                    // console.log(this.personaSubProgramas );

                }
            ).catch(error => {
                console.log(error);
            });
        },
        verDocumento(obj){  
            
            this.documentoPer = obj;
            this.$refs['archivo'].show();

        },

        actualizarArchivo(){
            this.ss.updatePersonaDocumento(this.documentoPer).then(
                (result) => {
                    let response = result.data;
                    this.documentoPer = response.data;

                }
            ).catch(error => {
                console.log(error);
            });
        },

        verDocProgPer(obj){
            this.documentoPerProg = obj;
            console.log("arch perprog",this.documentoPerProg)
            this.$refs['archivoProgPer'].show();

        },

        actualizarArchivoDos(){
            this.ss.updatePersonaDocumentoProg(this.documentoPerProg).then(
                (result) => {
                    let response = result.data;
                    this.documentoPerProg = response.data;

                }
            ).catch(error => {
                console.log(error);
            });
        },


        draw() {
            window.$('.btn-datatable-Persona').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showPersona(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getUnidadAcademica();
            this.getRol();
        }
    }
};
