var axios = require('axios');

export default class MainService {

    constructor() {
        var token = JSON.parse(localStorage.getItem('token'));
        axios.defaults.baseURL = process.env.VUE_APP_MAIN_SERVICE;
        if (token) {
            axios.defaults.headers.common = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }else{
            axios.defaults.headers.common = {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    getToken() {
        return axios.defaults.headers.common;
    }

    login(item) {
        return axios.post('api/login', item);
    }

    uploadFile(item) {
        return axios.post('api/uploadFile', item);
    }

    loginToken365(item) {
        return axios.post('api/loginToken365', item);
    }

    //Persona
    indexPersona() {
        return axios.defaults.baseURL + 'api/Persona/index';
    }

    showPersona(id) {
        return axios.get('api/Persona/show?id=' + id);
    }

    listPersona() {
        return axios.get('api/Persona/list');
    }

    storePersona(item) {
        return axios.post('api/Persona/store', item);
    }

    destroyPersona(item) {
        return axios.post('api/Persona/destroy', item);
    }

    select2Persona(q) {
        return axios.get('api/Persona/select2?q=' + q);
    }

    changePassword(item) {
        return axios.post('api/Persona/changePassword', item);
    }

    showUnidadAcademica(id) {
        return axios.get('api/UnidadAcademica/show?id=' + id);
    }

    indexUnidadAcademica() {
        return axios.defaults.baseURL + 'api/UnidadAcademica/index';
    }

    listUnidadAcademica() {
        return axios.get('api/UnidadAcademica/list');
    }

    storeUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/store', item);
    }

    destroyUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/destroy', item);
    }

    showRol(id) {
        return axios.get('api/Rol/show?id=' + id);
    }

    indexRol() {
        return axios.defaults.baseURL + 'api/Rol/index';
    }

    listRol() {
        return axios.get('api/Rol/list');
    }

    storeRol(item) {
        return axios.post('api/Rol/store', item);
    }

    destroyRol(item) {
        return axios.post('api/Rol/destroy', item);
    }

    //TipoReporte
    showTipoReporte(id) {
        return axios.get('api/TipoReporte/show?id=' + id);
    }

    indexTipoReporte() {
        return axios.defaults.baseURL + 'api/TipoReporte/index';
    }

    listTipoReporte() {
        return axios.get('api/TipoReporte/list');
    }

    storeTipoReporte(item) {
        return axios.post('api/TipoReporte/store', item);
    }

    destroyTipoReporte(item) {
        return axios.post('api/TipoReporte/destroy', item);
    }

    generateTipoReporte(item) {
        return axios.post('api/TipoReporte/generate', item);
    }

    //CriterioPuntaje
    showCriterioPuntaje(id) {
        return axios.get('api/CriterioPuntaje/show?id=' + id);
    }

    indexCriterioPuntaje() {
        return axios.defaults.baseURL + 'api/CriterioPuntaje/index';
    }

    listCriterioPuntaje() {
        return axios.get('api/CriterioPuntaje/list');
    }

    storeCriterioPuntaje(item) {
        return axios.post('api/CriterioPuntaje/store', item);
    }

    destroyCriterioPuntaje(item) {
        return axios.post('api/CriterioPuntaje/destroy', item);
    }

    //NivelAcademico
    showNivelAcademico(id) {
        return axios.get('api/NivelAcademico/show?id=' + id);
    }

    indexNivelAcademico() {
        return axios.defaults.baseURL + 'api/NivelAcademico/index';
    }

    listNivelAcademico() {
        return axios.get('api/NivelAcademico/list');
    }

    storeNivelAcademico(item) {
        return axios.post('api/NivelAcademico/store', item);
    }

    destroyNivelAcademico(item) {
        return axios.post('api/NivelAcademico/destroy', item);
    }

    //Programa
    showPrograma(id) {
        return axios.get('api/Programa/show?id=' + id);
    }

    indexPrograma() {
        return axios.defaults.baseURL + 'api/Programa/index';
    }

    listPrograma() {
        return axios.get('api/Programa/list');
    }

    storePrograma(item) {
        return axios.post('api/Programa/store', item);
    }

    destroyPrograma(item) {
        return axios.post('api/Programa/destroy', item);
    }

    //TipoMiembro
    showTipoMiembro(id) {
        return axios.get('api/TipoMiembro/show?id=' + id);
    }

    indexTipoMiembro() {
        return axios.defaults.baseURL + 'api/TipoMiembro/index';
    }

    listTipoMiembro() {
        return axios.get('api/TipoMiembro/list');
    }

    storeTipoMiembro(item) {
        return axios.post('api/TipoMiembro/store', item);
    }

    destroyTipoMiembro(item) {
        return axios.post('api/TipoMiembro/destroy', item);
    }

    //Defensa
    showDefensa(id) {
        return axios.get('api/Defensa/show?id=' + id);
    }

    indexDefensa() {
        return axios.defaults.baseURL + 'api/Defensa/index';
    }

    listDefensa() {
        return axios.get('api/Defensa/list');
    }

    storeDefensa(item) {
        return axios.post('api/Defensa/store', item);
    }

    destroyDefensa(item) {
        return axios.post('api/Defensa/destroy', item);
    }

    closeDefensa(item) {
        return axios.get('api/Defensa/close', item);
    }

    printDefensa(item) {
        return axios.get('api/Defensa/print', item);
    }

    //PersonaDefensa
    showPersonaDefensa(id) {
        return axios.get('api/PersonaDefensa/show?id=' + id);
    }

    indexPersonaDefensa() {
        return axios.defaults.baseURL + 'api/PersonaDefensa/index';
    }

    listPersonaDefensa() {
        return axios.get('api/PersonaDefensa/list');
    }

    storePersonaDefensa(item) {
        return axios.post('api/PersonaDefensa/store', item);
    }

    destroyPersonaDefensa(item) {
        return axios.post('api/PersonaDefensa/destroy', item);
    }

    closePersonaDefensa(item) {
        return axios.get('api/PersonaDefensa/close', item);
    }

    printPersonaDefensa(item) {
        return axios.get('api/PersonaDefensa/print', item);
    }

    //Puntaje
    showPuntaje(id) {
        return axios.get('api/Puntaje/show?id=' + id);
    }

    indexPuntaje() {
        return axios.defaults.baseURL + 'api/Puntaje/index';
    }

    listPuntaje(item) {
        return axios.get('api/Puntaje/list',item);
    }

    storePuntaje(item) {
        return axios.post('api/Puntaje/store', item);
    }

    destroyPuntaje(item) {
        return axios.post('api/Puntaje/destroy', item);
    }

    //Titulo
    showTitulo(id) {
        return axios.get('api/Titulo/show?id=' + id);
    }

    indexTitulo() {
        return axios.defaults.baseURL + 'api/Titulo/index';
    }

    listTitulo(item) {
        return axios.get('api/Titulo/list',item);
    }

    storeTitulo(item) {
        return axios.post('api/Titulo/store', item);
    }

    destroyTitulo(item) {
        return axios.post('api/Titulo/destroy', item);
    }

    syncTitulo() {
        return axios.get('api/Titulo/syncTitulo');
    }

    printTitulo(item) {
        return axios.get('api/Titulo/print', item);
    }

    bucarEstudiante(ci) {
        return axios.get('api/Persona/buscarsaga?CI=' + ci);
    }

    bucarDocente(ci) {
        return axios.get('api/Persona/buscarsagadocente?CI=' + ci);
    }

    verificarPersona(ci) {
        return axios.get('api/Persona/verificar?CI=' + ci);
    }



    //TipoDocumento
    showTipoDocumento(id) {
        return axios.get('api/TipoDocumento/show?id=' + id);
    }

    indexTipoDocumento() {
        return axios.defaults.baseURL + 'api/TipoDocumento/index';
    }

    listTipoDocumento() {
        return axios.get('api/TipoDocumento/list');
    }

    storeTipoDocumento(item) {
        return axios.post('api/TipoDocumento/store', item);
    }

    destroyTipoDocumento(item) {
        return axios.post('api/TipoDocumento/destroy', item);
    }

     //TipoDocumento
     showPersonaDocumento(id) {
        return axios.get('api/PersonaDocumento/show?id=' + id);
    }

    indexPersonaDocumento() {
        return axios.defaults.baseURL + 'api/PersonaDocumento/index';
    }

    listPersonaDocumento(id) {
        return axios.get('api/PersonaDocumento/list?Persona=' + id);
    }

    storePersonaDocumento(item) {
        return axios.post('api/PersonaDocumento/store', item);
    }

    destroyPersonaDocumento(item) {
        return axios.post('api/PersonaDocumento/destroy', item);
    }

    updatePersonaDocumento(item) {
        return axios.post('api/PersonaDocumento/update', item);
    }


    //PersonaPrograma
    showPersonaPrograma(id) {
        return axios.get('api/PersonaPrograma/show?id=' + id);
    }

    indexPersonaPrograma() {
        return axios.defaults.baseURL + 'api/PersonaPrograma/index';
    }

    listPersonaPrograma(id) {
        return axios.get('api/PersonaPrograma/list?Persona=' + id);
    }

    storePersonaPrograma(item) {
        return axios.post('api/PersonaPrograma/store', item);
    }

    destroyPersonaPrograma(item) {
        return axios.post('api/PersonaPrograma/destroy', item);
    }


    //PersonaProgramaSub
    showPersonaProgramaSub(id) {
        return axios.get('api/PersonaProgramaSub/show?id=' + id);
    }

    indexPersonaProgramaSub() {
        return axios.defaults.baseURL + 'api/PersonaProgramaSub/index';
    }

    listPersonaProgramaSub(id) {
        return axios.get('api/PersonaProgramaSub/list?PersonaPrograma=' + id);
    }

    storePersonaProgramaSub(item) {
        return axios.post('api/PersonaProgramaSub/store', item);
    }

    destroyPersonaProgramaSub(item) {
        return axios.post('api/PersonaProgramaSub/destroy', item);
    }


    //PersonaProgramaSubSub
    showPersonaProgramaSubSub(id) {
        return axios.get('api/PersonaProgramaSubSub/show?id=' + id);
    }

    indexPersonaProgramaSubSub() {
        return axios.defaults.baseURL + 'api/PersonaProgramaSubSub/index';
    }

    listPersonaProgramaSubSub() {
        return axios.get('api/PersonaProgramaSubSub/list');
    }

    storePersonaProgramaSubSub(item) {
        return axios.post('api/PersonaProgramaSubSub/store', item);
    }

    destroyPersonaProgramaSubSub(item) {
        return axios.post('api/PersonaProgramaSubSub/destroy', item);
    }


    // saga
    sincronizarSagaProg(ci) {
        return axios.get('api/PersonaPrograma/syncSagaProgramas?CI=' + ci);
    }

    updatePersonaDocumentoProg(item) {
        return axios.post('api/PersonaPrograma/update', item);
    }


    //TipoTrabajo
    showTipoTrabajo(id) {
        return axios.get('api/TipoTrabajo/show?id=' + id);
    }

    indexTipoTrabajo() {
        return axios.defaults.baseURL + 'api/TipoTrabajo/index';
    }

    listTipoTrabajo() {
        return axios.get('api/TipoTrabajo/list');
    }

    storeTipoTrabajo(item) {
        return axios.post('api/TipoTrabajo/store', item);
    }

    destroyTipoTrabajo(item) {
        return axios.post('api/TipoTrabajo/destroy', item);
    }
}