import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "PersonaDefensaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PersonaDefensaPage",
            ss: ss,
            ajax: {
                "url": ss.indexPersonaDefensa(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Persona', name: 'p.Persona', title: 'Persona' },
                { data: 'Titulo', name: 'd.Titulo', title: 'Titulo' },
                { data: 'TipoMiembro', name: 'tm.TipoMiembro', title: 'TipoMiembro' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            personadefensas: [],
            personadefensa: {},
            personas: [],
            persona:{},
            queryPersona: "",
            defensas:[],
            tipomiembros:[],
            isLoading: false,
            errorBag: {}
        };
    },
    methods: {
        getDefensas() {
            this.ss.listDefensa().then(result => {
                let response = result.data;
                this.defensas = response.data;
            })
        },
        getTipoMiembros() {
            this.ss.listTipoMiembro().then(result => {
                let response = result.data;
                this.tipomiembros = response.data;
            })
        },
        newPersonaDefensa() {
            this.personadefensa = {};
            this.$refs['frm-personadefensa'].show();
        },
        showPersonaDefensa(id) {
            this.isLoading=true;
            this.ss.showPersonaDefensa(id).then(
                (result) => {
                    let response = result.data;
                    this.personadefensa = response.data;
                    this.$refs['view-personadefensa'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editPersonaDefensa() {
            this.$refs['frm-personadefensa'].show();
            this.$refs['view-personadefensa'].hide();
        },
        cancelPersonaDefensa() {
            if (this.personadefensa.id) {
                this.$refs['view-personadefensa'].show();
            }
            this.$refs['frm-personadefensa'].hide();
        },
        savePersonaDefensa() {
            this.ss.storePersonaDefensa(this.personadefensa).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-personadefensa'].hide();
                    this.$refs['datatable-personadefensa'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deletePersonaDefensa() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyPersonaDefensa(this.personadefensa)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-personadefensa'].hide();
                                this.$refs['datatable-personadefensa'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-PersonaDefensa').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showPersonaDefensa(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    watch: {
        queryPersona(q) {
            this.ss.select2Persona(q).then((res) => {
                console.log(res);
                if (res.data.success) {
                    this.personas = res.data.items;
                } else {
                    this.personas = [];
                }
            })
        }
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }else{
            this.getDefensas();
            this.getTipoMiembros();
        }
    }
};
