import Vue from 'vue'
import Router from 'vue-router'
import LoginPage from '@/pages/Login/LoginPage.vue'
import UnidadAcademicaPage from '@/pages/UnidadAcademica/UnidadAcademicaPage.vue'
import RolPage from '@/pages/Rol/RolPage.vue'
import PersonaPage from '@/pages/Persona/PersonaPage.vue'
import PrincipalPage from '@/pages/Principal/PrincipalPage.vue'
import ProfilePage from '@/pages/Profile/ProfilePage.vue'
import TipoReportePage from '@/pages/TipoReporte/TipoReportePage.vue'
import TipoReporteVerPage from '@/pages/TipoReporteVer/TipoReporteVerPage.vue'
import CriterioPuntajePage from '@/pages/CriterioPuntaje/CriterioPuntajePage.vue'
import NivelAcademicoPage from '@/pages/NivelAcademico/NivelAcademicoPage.vue'
import ProgramaPage from '@/pages/Programa/ProgramaPage.vue'
import TipoMiembroPage from '@/pages/TipoMiembro/TipoMiembroPage.vue'
import DefensaPage from '@/pages/Defensa/DefensaPage.vue'
import PersonaDefensaPage from '@/pages/PersonaDefensa/PersonaDefensaPage.vue'
import PuntajePage from '@/pages/Puntaje/PuntajePage.vue'
import TituloPage from '@/pages/Titulo/TituloPage.vue'
import TipoDocumentoPage from '@/pages/TipoDocumento/TipoDocumentoPage.vue'

import PersonaProgramaPage from '@/pages/PersonaPrograma/PersonaProgramaPage.vue'
import PersonaProgramaSubPage from '@/pages/PersonaProgramaSub/PersonaProgramaSubPage.vue'
import TipoTrabajoPage from '@/pages/TipoTrabajo/TipoTrabajoPage.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'PrincipalPage',
      component: PrincipalPage
    },
    {
      path: '/UnidadAcademica',
      name: 'Unidad Academica',
      component: UnidadAcademicaPage
    },
    {
      path: '/Rol',
      name: 'Rol',
      component: RolPage
    },
    {
      path: '/Persona',
      name: 'Persona',
      component: PersonaPage
    },
    {
      path: '/Profile',
      name: 'Perfil',
      component: ProfilePage
    },
    {
      path: '/Login',
      name: 'Login',
      component: LoginPage
    },
    {
      path: '/TipoReporte',
      name: 'TipoReporte',
      component: TipoReportePage
    },
    {
      path: '/TipoReporteVer',
      name: 'TipoReporteVer',
      component: TipoReporteVerPage
    },
    {
      path: '/CriterioPuntaje',
      name: 'CriterioPuntaje',
      component: CriterioPuntajePage
    },
    {
      path: '/NivelAcademico',
      name: 'NivelAcademico',
      component: NivelAcademicoPage
    },
    {
      path: '/Programa',
      name: 'Programa',
      component: ProgramaPage
    },
    {
      path: '/TipoMiembro',
      name: 'TipoMiembro',
      component: TipoMiembroPage
    },
    {
      path: '/Defensa',
      name: 'Defensa',
      component: DefensaPage
    },
    {
      path: '/PersonaDefensa',
      name: 'PersonaDefensa',
      component: PersonaDefensaPage
    },
    {
      path: '/Puntaje',
      name: 'Puntaje',
      component: PuntajePage
    },
    {
      path: '/Titulo',
      name: 'Titulo',
      component: TituloPage
    },
    {
      path: '/TipoDocumento',
      name: 'TipoDocumento',
      component: TipoDocumentoPage
    },
    {
      path: '/PersonaPrograma',
      name: 'PersonaPrograma',
      component: PersonaProgramaPage
    },
    {
      path: '/PersonaProgramaSub',
      name: 'PersonaProgramaSub',
      component: PersonaProgramaSubPage
    }
    ,
    {
      path: '/TipoTrabajo',
      name: 'TipoTrabajo',
      component: TipoTrabajoPage
    }
  ]
})