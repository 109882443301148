<template>
    <v-list nav dense>
        <v-list-group v-if="persona.Rol == 1">
            <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon>mdi-cog-box</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Configuracion</v-list-item-title>
                </v-list-item-content>
            </template>

            <router-link
                class="black--text text--accent-4"
                to="UnidadAcademica"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-school</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.UnidadAcademica")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="Rol"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-key</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Rol")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="NivelAcademico"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-arrow-expand-right</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.NivelAcademico")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="Programa"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-road</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Programa")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="TipoMiembro"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-multiple-plus</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.TipoMiembro")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="CriterioPuntaje"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-key</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.CriterioPuntaje")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="TipoTrabajo"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-file</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Tipo Trabajo</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

        </v-list-group>

        
        <v-list-group v-if="persona.Rol == 1">
            <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon>mdi-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Relacionales</v-list-item-title>
                </v-list-item-content>
            </template>
            

            <router-link
                class="black--text text--accent-4"
                to="PersonaDefensa"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-multiple</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.PersonaDefensa")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="PersonaPrograma"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-multiple</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("Persona - Programa")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            

            <router-link
                class="black--text text--accent-4"
                to="Puntaje"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-multiple</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Puntaje")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
        </v-list-group>
        <v-list-item-group>
            <router-link
                class="black--text text--accent-4"
                to="Persona"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 2 || persona.Rol == 3 || persona.Rol == 6"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-multiple</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Persona")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="Defensa"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 2 || persona.Rol == 3 || persona.Rol == 4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-clipboard-account</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Defensa")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="Titulo"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 7"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-folder-account</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Titulo")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="Profile"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-circle</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Profile")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="TipoDocumento"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 || persona.Rol == 2 || persona.Rol == 3 || persona.Rol == 6"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-multiple-plus</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Tipo Documento</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="TipoReporte"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 "
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-note-text</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.TipoReporte")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <router-link
                class="black--text text--accent-4"
                to="TipoReporteVer"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1 "
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-file-chart</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.TipoReporteVer")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
        </v-list-item-group>
    </v-list>
</template>

<script>
export default {
    name: "MenuVuetify",
    data() {
        return {
            msg: "MenuVuetify",
            persona:{}
        };
    },
    mounted() {
        this.persona = JSON.parse(localStorage.getItem("persona"));
    },
};
</script>