import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");
import moment from 'moment';
import accounting from 'accounting';

export default {
    name: "DefensaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "DefensaPage",
            ss: ss,
            ajax: {
                "url": ss.indexDefensa(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Persona', name: 'p.Persona', title: 'Persona' },
                { data: 'Titulo', name: 'd.Titulo', title: 'Titulo' },
                { data: 'Programa', name: 'pr.Programa', title: 'Programa' },
                { data: 'UnidadAcademica', name: 'ua.UnidadAcademica', title: 'Unidad Académica' },
                { data: 'Gestion', name: 'd.Gestion', title: 'Gestion' },
                { data: 'PeriodoGestion', name: 'd.PeriodoGestion', title: 'Periodo' },
                {
                    data: 'FechaHora', name: 'd.FechaHora', title: 'Fecha y Hora', orderable: true, searchable: true,
                    render: function (data, type, row) {
                        return moment(row.FechaHora).format('DD-MM-YYYY HH:mm');
                    }
                },
                {
                    data: 'PromedioDefensa', name: 'd.PromedioDefensa', title: 'Promedio Defensa', orderable: true, searchable: true,
                    render: function (data, type, row) {
                        return accounting.formatMoney(row.PromedioDefensa, "", 2, ".", ",");
                    }
                },
                {
                    data: 'PromedioTotal', name: 'd.PromedioTotal', title: 'Promedio Total', orderable: true, searchable: true,
                    render: function (data, type, row) {
                        return accounting.formatMoney(row.PromedioTotal, "", 2, ".", ",");
                    }
                },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            defensas: [],
            defensa: {},
            personas: [],
            persona: {},
            personadefensa: {},
            tipomiembros: [],
            puntajes: [],
            queryPersona: "",
            isLoading: false,
            unidadacademicas: [],
            programas: [],
            accedeNotas: false,
            notaManual: false,
            errorBag: {},
            selectedPersona:0,
            personaProgramas:[],
            tipotrabajos:[]

        };
    },
    methods: {

        getTipoTrabajos() {
            this.ss.listTipoTrabajo().then(result => {
                let response = result.data;
                this.tipotrabajos = response.data;
            })
        },

        getTipoMiembros() {
            this.ss.listTipoMiembro().then(result => {
                let response = result.data;
                this.tipomiembros = response.data;
            })
        },
        getUnidadAcademicas() {
            this.ss.listUnidadAcademica().then(result => {
                let response = result.data;
                this.unidadacademicas = response.data;
            })
        },
        getProgramas() {
            this.ss.listPrograma().then(result => {
                let response = result.data;
                this.programas = response.data;
            })
        },
        newDefensa() {
            this.defensa = {};
            this.$refs['frm-defensa'].show();
        },
        newPersonaDefensa() {
            this.personadefensa = { Defensa: this.defensa.id };
            this.$refs['frm-personadefensa'].show();
            this.$refs['view-defensa'].hide();
        },
        cancelPersonaDefensa() {
            if (this.defensa.id) {
                this.$refs['view-defensa'].show();
            }
            this.$refs['frm-personadefensa'].hide();
        },
        savePersonaDefensa() {
            this.ss.storePersonaDefensa(this.personadefensa).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-personadefensa'].hide();
                    if (this.defensa.id) {
                        this.showDefensa(this.defensa.id);
                    }
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deletePersonaDefensa(pd) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyPersonaDefensa(pd)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                if (this.defensa.id) {
                                    this.showDefensa(this.defensa.id);
                                }
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        calificaPersonaDefensa(personaDefensa) {
            this.ss.listPuntaje({ params: { PersonaDefensa: personaDefensa } }).then(
                (result) => {
                    this.puntajes = result.data.data;
                    this.$refs['view-defensa'].hide();
                    this.$refs['view-puntaje'].show();
                })
                .catch(error => console.log(error));
        },

        savePuntaje(puntaje) {
            puntaje.Puntaje = puntaje.Puntaje.replace(",", ".");
            // if (puntaje.Puntaje > 10 || puntaje.Puntaje < 7.1) {
                if (puntaje.Puntaje > 100 || puntaje.Puntaje < 71) {
                this.$bvToast.toast(
                    'Error, verifique que su puntaje este entre 71 y 100', 'Oops!',
                    {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    }
                )
            } else {
                this.ss.storePuntaje(puntaje).then(result => {
                    let response = result.data;
                    console.log(response);
                })
                    .catch(error => console.log(error));
            }
        },

        closePuntaje() {

            this.$refs['view-puntaje'].hide();
            if (this.defensa.id) {
                this.showDefensa(this.defensa.id);
            }
        },
        showDefensa(id) {
            this.isLoading = true;
            this.ss.showDefensa(id).then(
                (result) => {
                    let response = result.data;
                    this.defensa = response.data;
                    this.accedeNotas = false;
                    this.notaManual = false;
                    this.defensa.persona_defensa.forEach(jurado => {
                        if (jurado.Persona == this.persona.id) {
                            if (jurado.tipo_miembro.AccedeNotas) {
                                this.accedeNotas = true;
                            }
                        }
                    });
                    this.$refs['view-defensa'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        editDefensa() {
            this.$refs['frm-defensa'].show();
            this.$refs['view-defensa'].hide();
        },
        cancelDefensa() {
            if (this.defensa.id) {
                this.$refs['view-defensa'].show();
            }
            this.$refs['frm-defensa'].hide();
        },
        saveDefensa() {
            this.ss.storeDefensa(this.defensa).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-defensa'].hide();
                    this.$refs['datatable-defensa'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteDefensa() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyDefensa(this.defensa)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-defensa'].hide();
                                this.$refs['datatable-defensa'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        closePersonaDefensa(personaDefensa) {
            this.$swal({
                title: "Estas seguro que deseas finalizar?",
                text: "No podrá cambiar las calificaciones!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.closePersonaDefensa({ params: { id: personaDefensa } })
                            .then((result) => {
                                let response = result.data;
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                if (this.defensa.id) {
                                    this.showDefensa(this.defensa.id);
                                }
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        printPersonaDefensa(personaDefensa){
            this.isLoading = true;
            this.ss.printPersonaDefensa({ params: { id: personaDefensa.id } })
                .then((result) => {
                    let response = result.data;
                    var a = document.createElement("a");
                    a.href = response.data.url;
                    a.target = '_blank';
                    a.click();
                    this.isLoading = false;
                })
                .catch( error => {
                    console.log( error );
                });
        },
        printDefensa(option){
            if (!this.defensa.Finalizado&&(option!=6)){
                this.$bvToast.toast(
                    'Por favor Finaliza la calificacion de la Defensa para imprimir las actas.',
                    {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    }
                )
            }else{
                this.isLoading = true;
                this.ss.printDefensa({ params: { id: this.defensa.id , Tipo: option }})
                    .then( result => {
                        let response = result.data;
                        var a = document.createElement("a");
                        a.href = response.data.url;
                        a.target = '_blank';
                        a.click();
                        this.isLoading = false;
                    })
                    .catch( error => {
                        console.log( error );
                    });
            }
        },
        closeDefensa() {
            this.$swal({
                title: "Estas seguro que deseas finalizar?",
                text: "No podrá cambiar realizar más modificaciones a las notas, si desea revisar sus calificaciones cierre este mensaje!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.closeDefensa({ params: { id: this.defensa.id } })
                            .then((result) => {
                                let response = result.data;
                                if (response.success) {
                                    this.$bvToast.toast(
                                        response.msg,
                                        {
                                            title: 'Correcto',
                                            variant: 'success',
                                            autoHideDelay: 5000
                                        }
                                    )
                                } else {
                                    this.$bvToast.toast(
                                        response.msg,
                                        {
                                            title: 'Error',
                                            variant: 'danger',
                                            autoHideDelay: 5000
                                        }
                                    )
                                }
                                this.showDefensa(this.defensa.id);
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        getPersonaProgramas(id) {
            this.ss.listPersonaPrograma(id).then(
                (result) => {
                    let response = result.data;
                    this.personaProgramas = response.data;
                    console.log(this.personaProgramas);


                }
            ).catch(error => {
                console.log(error);
            });
        },
        draw() {
            window.$('.btn-datatable-Defensa').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showDefensa(data);
            });
        }
    },
    watch: {
        queryPersona(q) {
            this.ss.select2Persona(q).then((res) => {
                console.log(res);
                if (res.data.success) {
                    this.personas = res.data.items;
                } else {
                    this.personas = [];
                }
            })
        },

        selectedPersona(value) {
            // console.log("value",value);
            this.getPersonaProgramas(value.id);
        },
    
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        this.persona = JSON.parse(localStorage.getItem('persona'));
        if (!this.persona) {
            this.$router.push('/Login');
        } else {
            this.getUnidadAcademicas();
            this.getProgramas();
            this.getTipoMiembros();
            this.getTipoTrabajos();
        }
    }
};
